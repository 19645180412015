import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { useMemo } from "react";

import { About } from "./pages/About";
import { Block } from "./pages/Block";
import { Blocks } from "./pages/Blocks";
import { Departures } from "./pages/Departures";
import { HiddenTrips } from "./pages/HiddenTrips";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { Map } from "./pages/Map";
import { Navigation } from "./components/Navigation";
import { Route as RoutePage } from "./pages/Route";
import { Routes as RoutesPage } from "./pages/Routes";
import { Status } from "./pages/Status";
import { Stop } from "./pages/Stop";
import { Stops } from "./pages/Stops";
import { Trip } from "./pages/Trip";
import { Vehicle } from "./pages/Vehicle";
import { VehicleHistory } from "./pages/VehicleHistory";
import { Vehicles } from "./pages/Vehicles";
import { VehiclesByRoute } from "./pages/VehiclesByRoute";
import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import { getUrlForMap } from "./urls";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Shortcut } from "./pages/Shortcut";
// import Alert from "react-bootstrap/Alert";
// import Container from "react-bootstrap/Container";

interface RedirectProps {
  to: string;
}

function Redirect({ to }: RedirectProps) {
  const params = useParams();

  const url = useMemo(() => {
    return (
      "/" +
      to
        .split("/")
        .map((part) => (part.startsWith(":") ? params[part.slice(1)] : part))
        .join("/")
    );
  }, [to, params]);

  return <Navigate replace to={url} />;
}

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export function App() {
  useGoogleAnalytics();

  const location = useLocation();

  return (
    <div>
      {location.pathname !== getUrlForMap() && (
        <>
          <Navigation />
          {/* <Alert variant="warning" className="px-0 py-2 rounded-0">
            <Container>
              ⚠️&ensp;Godziny odjazdów w niedzielę po zmianie czasu z zimowego
              na letni w publikowanych danych GTFS Static są nieprawidłowe, tj.
              przesunięte o godzinę do tyłu względem oficjalnych rozkładów
              jazdy. Prosimy zachować ostrożność przy planowaniu podróży.
            </Container>
          </Alert> */}
        </>
      )}
      <SentryRoutes>
        <Route path="" element={<Home />} />
        <Route path="/:shortcut" element={<Shortcut />} />
        <Route path="mapa" element={<Map />} />
        <Route path="mapa/:selectionString" element={<Map />} />
        <Route path="linie" element={<RoutesPage />} />
        <Route path="pojazdy-na-liniach" element={<VehiclesByRoute />} />
        <Route path="linie/:routeShortName" element={<RoutePage />} />
        <Route
          path="linie/:routeShortName/kierunki/:directionId"
          element={<Departures />}
        />
        <Route
          path="linie/:routeShortName/kierunki/:directionId/przystanki/:encodedStopName"
          element={<Departures />}
        />
        <Route path="brygady/:carrier" element={<Blocks />} />
        <Route
          path="brygady/:category/:serviceId/:blockId"
          element={<Block />}
        />
        <Route
          path="brygady/:category/:serviceId/:blockId/kursy/:tripId"
          element={<Trip />}
        />
        <Route path="kursy-zjazdowe-i-wyjazdowe" element={<HiddenTrips />} />
        <Route path="przystanki" element={<Stops />} />
        <Route path="przystanki/:encodedStopName" element={<Stop />} />
        <Route path="przystanki/:encodedStopName/:mode" element={<Stop />} />
        <Route path="pojazdy" element={<Vehicles />} />
        <Route path="pojazdy/:kmk_id" element={<Vehicle />} />
        <Route path="pojazdy/:kmk_id/historia" element={<VehicleHistory />} />
        <Route path="status" element={<Status />} />
        <Route path="informacje" element={<About />} />
        <Route path="polityka-prywatnosci" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
        {process.env.PUBLIC_URL !== "https://odjazdowykrakow.pl" && (
          <>
            <Route path="map" element={<Redirect to="mapa" />} />
            <Route
              path="map/:selectionString"
              element={<Redirect to="mapa/:selectionString" />}
            />
            <Route path="routes" element={<Redirect to="linie" />} />
            <Route
              path="routes/vehicles"
              element={<Redirect to="pojazdy-na-liniach" />}
            />
            <Route
              path="routes/:routeShortName"
              element={<Redirect to="linie/:routeShortName" />}
            />
            <Route
              path="routes/:routeShortName/directions/:directionId"
              element={
                <Redirect to="linie/:routeShortName/kierunki/:directionId" />
              }
            />
            <Route
              path="routes/:routeShortName/directions/:directionId/stops/:stopName/departures"
              element={
                <Redirect to="linie/:routeShortName/kierunki/:directionId/przystanki/:stopName" />
              }
            />
            <Route path="brygady" element={<Redirect to="brygady/mpk" />} />
            <Route path="blocks" element={<Redirect to="brygady/mpk" />} />
            <Route
              path="blocks/:category/:serviceId/:blockId"
              element={<Redirect to="brygady/:category/:serviceId/:blockId" />}
            />
            <Route
              path="blocks/:category/:serviceId/:blockId/trips/:tripId"
              element={
                <Redirect to="brygady/:category/:serviceId/:blockId/kursy/:tripId" />
              }
            />
            <Route
              path="trips/hidden"
              element={<Redirect to="kursy-zjazdowe-i-wyjazdowe" />}
            />
            <Route path="stops" element={<Redirect to="przystanki" />} />
            <Route
              path="stops/:encodedStopName"
              element={<Redirect to="przystanki/:encodedStopName" />}
            />
            <Route
              path="stops/:encodedStopName/:mode"
              element={<Redirect to="przystanki/:encodedStopName/:mode" />}
            />
            <Route path="vehicles" element={<Redirect to="pojazdy" />} />
            <Route
              path="vehicles/:kmk_id"
              element={<Redirect to="pojazdy/:kmk_id" />}
            />
            <Route
              path="vehicles/:kmk_id/history"
              element={<Redirect to="pojazdy/:kmk_id/historia" />}
            />
            <Route path="about" element={<Redirect to="informacje" />} />
            <Route
              path="privacy-policy"
              element={<Redirect to="polityka-prywatnosci" />}
            />
          </>
        )}
      </SentryRoutes>
    </div>
  );
}
