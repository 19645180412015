import { Coords, useNearStops } from "../hooks/useNearStops";
import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import { Error } from "../components/Error";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import {
  getUrlForBlocks,
  getUrlForHiddenTrips,
  getUrlForMap,
  getUrlForPrivacyPolicy,
  getUrlForStop,
  getUrlForStops,
} from "../urls";
import Container from "react-bootstrap/Container";
import { useFavoriteStops } from "../hooks/useFavoriteStops";
// import Alert from "react-bootstrap/Alert";

function FavoriteStops() {
  const favoriteStops = useFavoriteStops();

  return (
    <>
      {favoriteStops.map((stopName) => (
        <React.Fragment key={stopName}>
          <Link to={getUrlForStop(stopName)}>
            <Button variant="outline-primary" className="mb-2 me-2 bold">
              {stopName}
            </Button>
          </Link>
        </React.Fragment>
      ))}
    </>
  );
}

interface NearStopsInnerProps {
  coords: Coords;
}

function NearStopsInner({ coords }: NearStopsInnerProps) {
  const { stops, loading, error } = useNearStops(coords);

  if (error) {
    return <Error error={error} />;
  }

  if (loading) {
    return (
      <Button variant="outline-secondary" className="mb-2" disabled>
        <Spinner
          animation="border"
          variant="secondary"
          role="status"
          size="sm"
          className="me-2"
        />
        Ładowanie&hellip;
      </Button>
    );
  }

  return (
    <>
      {stops.map((stop) => (
        <React.Fragment key={stop.stop_name}>
          <Link to={getUrlForStop(stop.stop_name)}>
            <Button variant="outline-primary" className="mb-2 me-2 bold">
              {stop.stop_name}
            </Button>
          </Link>
        </React.Fragment>
      ))}
    </>
  );
}

function NearStops() {
  const [state, setState] = useState<string>();
  const [requested, setRequested] = useState(false);
  const [coords, setCoords] = useState<Coords>();

  useEffect(() => {
    let ignore = false;
    (async () => {
      if (!navigator?.permissions?.query) {
        if (!ignore) {
          setState("failed");
        }
        return;
      }
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (!ignore) {
            setState(result.state);
          }
        })
        .catch(() => {
          if (!ignore) {
            setState("failed");
          }
        });
    })();
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    let ignore = false;
    if (requested || state === "granted") {
      if (!navigator?.geolocation?.getCurrentPosition) {
        if (!ignore) {
          setState("failed");
        }
        return;
      }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (!ignore) {
            setCoords(position.coords);
          }
        },
        () => {
          if (!ignore) {
            setState("failed");
          }
        }
      );
    }
    return () => {
      ignore = true;
    };
  }, [requested, state]);

  if (coords !== undefined) {
    return <NearStopsInner coords={coords} />;
  }

  if (state === undefined || state === "prompt" || state === "granted") {
    return (
      <Button
        variant="outline-primary"
        className="mb-2"
        onClick={() => setRequested(true)}
        disabled={state !== "prompt"}
      >
        Znajdź przystanki w pobliżu
      </Button>
    );
  }

  return (
    <Button
      variant="outline-secondary"
      className="mb-2"
      onClick={() => setRequested(true)}
      disabled
    >
      Nie udało się pobrać lokalizacji
    </Button>
  );
}

export function Home() {
  return (
    <div>
      {/* <Alert variant="warning" className="px-0 py-2 rounded-0">
        <Container>
          ⚠️&ensp;Godziny odjazdów w niedzielę po zmianie czasu z zimowego na
          letni w publikowanych danych GTFS Static są nieprawidłowe, tj.
          przesunięte o godzinę do tyłu względem oficjalnych rozkładów jazdy.
          Prosimy zachować ostrożność przy planowaniu podróży.
        </Container>
      </Alert> */}
      <Container className="pb-1">
        <h1 className="my-4">Strona główna</h1>
        <Link to={getUrlForMap()}>
          <Button variant="outline-primary" className="mb-4">
            Mapa lokalizacji pojazdów
          </Button>
        </Link>
        <br />
        <Link to={getUrlForStops()}>
          <Button variant="outline-primary" className="mb-4">
            Odjazdy z przystanków
          </Button>
        </Link>
        <br />
        <Link to={getUrlForBlocks("mpk")}>
          <Button variant="outline-primary" className="mb-4">
            Brygady
          </Button>
        </Link>
        <br />
        <Link to={getUrlForHiddenTrips()}>
          <Button variant="outline-primary" className="mb-4">
            Kursy zjazdowe i wyjazdowe
          </Button>
        </Link>
        <div className="mb-3">
          <NearStops />
        </div>
        <div className="mb-4">
          <FavoriteStops />
        </div>
        <div>
          <a
            href="https://apps.apple.com/us/app/odjazdowy-krak%C3%B3w/id6740146067?itscg=30200&itsct=apps_box_badge&mttnsubad=6740146067"
            target="_blank"
            rel="noreferrer"
            style={{ display: "inline-block" }}
            className="mb-3 me-2"
          >
            <img
              src="assets/app-store-badge.svg"
              alt="Pobierz w App Store"
              style={{
                width: 165,
                height: 55,
                verticalAlign: "middle",
                objectFit: "contain",
              }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=pl.tomekzaw.odjazdowykrk&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
            rel="noreferrer"
            style={{ display: "inline-block" }}
            className="mb-3"
          >
            <img
              alt="Pobierz z Google Play"
              src="assets/google-play-badge.svg"
              style={{
                width: 186,
                height: 55,
                verticalAlign: "middle",
                objectFit: "contain",
              }}
            />
          </a>
        </div>
        <p className="mt-3 text-secondary small">
          &copy; Tomasz Zawadzki {new Date().getFullYear()}.{" "}
          <Link to={getUrlForPrivacyPolicy()} className="text-secondary">
            Polityka prywatności
          </Link>
        </p>
        <p className="mt-1 text-muted small opacity-75">
          Apple, logo Apple, iPhone, oraz iPad są znakami towarowymi firmy Apple
          Inc. zarejestrowanymi w USA oraz innych krajach i regionach. App Store
          jest znakiem towarowym firmy Apple Inc.
          <br />
          Google Play i logo Google Play są znakami towarowymi firmy Google LLC.
        </p>
      </Container>
    </div>
  );
}
